import React, { useState, useEffect, lazy, Suspense } from "react";
import axios from "axios";
import NavBar from "./components/navbar";
import SearchBox from "./components/SearchBox";
import ImageResultHeader from "./components/ImageResultHeader";
import GridLayoutPerson from "./components/Grid/GridLayoutPerson";
import "./Styles/ScrollbarStyles.css";
import config from "./config.json";

// Load GridLayout lazily
const GridLayout = lazy(() => import("./components/Grid/GridLayout"));
const serviceUrl = config.serviceUrl;

function MainContent(props) {
  const [screen, setScreen] = useState("search");
  const [resultData, setResultData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [inputText, setInputText] = useState("");
  const [facialRecogData, setFacialRecogData] = useState([]);
  const [queryText, setQueryText] = useState("");
  const [exampleQueryStatus, setExampleQueryStatus] = useState(false);

  const [facialLoading, setFacialLoading] = useState(false);
  const [showImageResult, setShowImageResult] = useState(false);

  // Navbar
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollDirection, setScrollDirection] = useState(1);

  // Filters start
  const [isPhotoChecked, setIsPhotoChecked] = useState(true);
  const [isVideoChecked, setIsVideoChecked] = useState(true);
  const [orderBy, setOrderBy] = useState("distance");
  const [inputPersons, setInputPersons] = useState([]);
  const [queryThreshold, setQueryThreshold] = useState(config.queryThreshold);
  const [faceThreshold, setFaceThreshold] = useState(config.faceThreshold);
  const [transcriptThreshold, setTranscriptThreshold] = useState(
    config.transcriptThreshold
  );
  // const [foundFaces, setFoundFaces] = useState([]);
  // Filters end

  // Cookie set start
  const [isCookie, setIsCookie] = useState(false);
  const set_signed_cookie = async () => {
    try {
      const cookie_response = await axios.get(
        serviceUrl + "/set_signed_cookie",
        { withCredentials: true }
      );
      setIsCookie(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    set_signed_cookie();

    // Timer to refresh the cookie every 13 minutes
    const cookieRefreshTimer = setInterval(() => {
      set_signed_cookie();
    }, 780000);

    // Timer will be cleaned if component unmounts.
    return () => clearInterval(cookieRefreshTimer);
  }, []);
  // Cookie set end

  const handleScroll = (event) => {
    setScrollDirection(event.currentTarget.scrollTop < scrollTop ? 1 : 0);
    setScrollTop(event.currentTarget.scrollTop);
  };

  // Example Buttons
  useEffect(() => {
    if (exampleQueryStatus) {
      search();
      setExampleQueryStatus(false);
    }
  }, [exampleQueryStatus]);

  const handleExample = (event) => {
    event.preventDefault();
    setInputText(event.target.innerText);
    setExampleQueryStatus(true);
  };

  /* Search code start */
  const [searchType, setSearchType] = useState("text");

  async function fetch_data(page = 1, pageSize = 40) {
    const config = { headers: {} };
    let response;
    let requestBody = {
      person_list: inputPersons.map((person) => person.id),
      content_type_photo: isPhotoChecked,
      content_type_video: isVideoChecked,
      order_by: orderBy,
      face_threshold: faceThreshold,
      query_threshold: queryThreshold,
      transcript_threshold: transcriptThreshold,
      page: page,
      items_per_page: pageSize,
    };

    if (searchType === "text") {
      setSelectedFile(null);
      requestBody["query"] = inputText;
      response = await axios.post(serviceUrl + "/search", requestBody, config);
    } else if (searchType === "image") {
      const formData = new FormData();
      formData.append("file", selectedFile);
      // Append each property of the additionalData object to the formData
      Object.keys(requestBody).forEach((key) => {
        formData.append(key, requestBody[key]);
      });
      response = await axios.post(
        serviceUrl + "/search-image",
        formData,
        config
      );
    }
    if (response.data) {
      if (!isCookie) {
        set_signed_cookie();
      }
    }
    return response.data;
  }

  async function search(page = 1, pageSize = 40) {
    if (searchType === "image" && !selectedFile) {
      alert("Please select a file first.");
      return;
    }
    if (searchType === "text" && inputText === "") {
      alert("Please enter in a query.");
      return;
    }
    setLoading(true);
    setScreen("result");
    const response_data = await fetch_data(page, pageSize);
    setResultData(response_data);
    setLoading(false);
    if (searchType === "image") {
      setFacialRecogData([]);
      setShowImageResult(true);
      const formData = new FormData();
      formData.append("file", selectedFile);
      let responseFacial = await axios.post(
        serviceUrl + "/find-faces",
        formData,
        config
      );
      setFacialRecogData(responseFacial.data);
      setFacialLoading(false);
    }
  }
  /* Search code end */

  return (
    <>
      <NavBar scrollDirection={scrollDirection} />
      <main className="min-vh-100 pt-5">
        <div
          className={`container animated_height ${
            screen === "search" ? "home_screen" : "mt-3"
          } `}
        >
          <div className="d-flex flex-column justify-content-center h-100">
            <div className="row w-100 justify-content-center">
              <div className="col-lg-9 text-center">
                {screen === "search" && (
                  <img
                    src="/elastiq_pixels_logo.png"
                    alt="Elastiq Pixels"
                    height="60"
                    className="mb-5"
                  />
                )}
                <SearchBox
                  setResultData={setResultData}
                  setLoading={setLoading}
                  loading={loading}
                  setScreen={setScreen}
                  setSelectedFile={setSelectedFile}
                  selectedFile={selectedFile}
                  setInputText={setInputText}
                  inputText={inputText}
                  setQueryText={setQueryText}
                  setFacialRecogData={setFacialRecogData}
                  setFacialLoading={setFacialLoading}
                  setShowImageResult={setShowImageResult}
                  search={search}
                  searchType={searchType}
                  setSearchType={setSearchType}
                />
                {screen === "search" && (
                  <div className="my-5">
                    <button
                      role="button"
                      className="btn btn-light mx-3"
                      onClick={handleExample}
                    >
                      Person with a hard hat
                    </button>
                    <button
                      role="button"
                      className="btn btn-light mx-3"
                      onClick={handleExample}
                    >
                      Pouring Concrete
                    </button>
                    <button
                      role="button"
                      className="btn btn-light mx-3"
                      onClick={handleExample}
                    >
                      Truck
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

          {screen === "result" && selectedFile && showImageResult && (
            <ImageResultHeader
              setResultData={setResultData}
              setLoading={setLoading}
              selectedFile={selectedFile}
              imageSrc={URL.createObjectURL(selectedFile)}
              facialRecogData={facialRecogData}
              facialLoading={facialLoading}
            />
          )}

          {screen === "result" && (
            <Suspense fallback={<div>Loading...</div>}>
              <GridLayout
                loading={loading}
                data={resultData}
                handleScroll={handleScroll}
                setResultData={setResultData}
                query={queryText}
                selectedFile={selectedFile}
                /*Filters*/
                isPhotoChecked={isPhotoChecked}
                setIsPhotoChecked={setIsPhotoChecked}
                isVideoChecked={isVideoChecked}
                setIsVideoChecked={setIsVideoChecked}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                inputPersons={inputPersons}
                setInputPersons={setInputPersons}
                queryThreshold={queryThreshold}
                setQueryThreshold={setQueryThreshold}
                faceThreshold={faceThreshold}
                setFaceThreshold={setFaceThreshold}
                transcriptThreshold={transcriptThreshold}
                setTranscriptThreshold={setTranscriptThreshold}
                fetch_data={fetch_data}
              />
            </Suspense>
          )}

          {screen === "persons" && (
            <GridLayoutPerson
              loading={loading}
              data={resultData}
              setScreen={setScreen}
              setInputText={setInputText}
              setInputPersons={setInputPersons}
              handleScroll={handleScroll}
            />
          )}
        </div>
      </main>
    </>
  );
}
export default MainContent;
