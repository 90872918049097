import React, { useState, useEffect } from 'react';

const NavBar = (props) => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [scrollDirection, setScrollDirection] = useState('scrolled-up');

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;

      if (currentScrollPos > prevScrollPos) {
        setScrollDirection('scrolled-down');
      } else {
        setScrollDirection('scrolled-up');
      }

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);

  return (
    <div className={`position-fixed p-0 m-0 fixed-top row justify-content-end ${scrollDirection}`}>
      <div className={`col-12 col-md-3 col-lg-2 p-0`}></div>
      <div className={`col-12 transition-width p-0`}>
        <nav className="navbar navbar-expand-lg navbar-white bg-white border-bottom p-3">
          <a className="m-auto d-block" href="/">
            <img src="/elastiq_pixels_logo.png" alt="Elastiq Pixel" height="22" className="m-auto d-block" />
          </a>
        </nav>
      </div>
    </div>
  );
};

export default NavBar;
