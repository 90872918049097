import React from 'react';
import { useState, useEffect } from 'react';

import axios from 'axios';
import config from '../config.json';



function VerifyFaces(props) {
    const serviceUrl = config.serviceUrl;
    const [lastCookieTime, setLastCookieTime] = useState(0);
    const [imgData, setImgData] = useState([]);


    function checkCookie(cookieName) {
        var encodedCookieName = encodeURIComponent(cookieName) + "=";
        var cookieString = document.cookie;
        const cookie_exists = cookieString.includes(encodedCookieName);

        let now = new Date();
        let epoch_time_in_seconds = Math.floor(now.getTime() / 1000);

        return ((!cookie_exists) || (lastCookieTime === 0) || (lastCookieTime - epoch_time_in_seconds < 450))
    }

    async function get_faces() {
        // console.log("calling function")
        const response = await axios.get(serviceUrl + "/verify_faces");
        if (response.data) {
            if (checkCookie("Cloud-CDN-Cookie")) {
                const cookie_response = await axios.get(serviceUrl + "/set_signed_cookie", { withCredentials: true });
                setLastCookieTime(parseInt(cookie_response.data));
            }
            setImgData(response.data);
        }

    }
    useEffect(() => {
        get_faces();
    }, []);

    return (
        <>
            {imgData.map((person, i) => {
                return (
                    <div className="row mb-1">
                        <div className="col-12 d-flex">
                            {person.id}
                            {" "}
                            {person.faces.length}
                            {person.faces.map((f) => {
                                return <>
                                    <div style={{
                                        width: `${160 * (f[3] / f[4])}px`,    // Replace 'W' with the actual width value
                                        height: `160px`,   // Replace 'H' with the actual height value
                                        overflow: 'hidden',
                                        position: 'relative'
                                    }}>
                                        <img
                                            src={f[0]} // Replace with your actual image URL
                                            style={{
                                                position: 'absolute',
                                                top: `-${f[2]*(160/f[4])}px`,
                                                left: `-${f[1]*(160/f[4])}px`,
                                                transformOrigin: 'top left',
                                                transform: `scale(${160/f[4]})`
                                            }}
                                        />
                                        
                                    </div>
                                    {/* {f[0].split("/").slice(-1)[0]} */}
                                </>
                            })}
                        </div>
                    </div>
                )
            })}
        </>
    );
};
export default VerifyFaces;