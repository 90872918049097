import { useState, useEffect } from "react";
import axios from "axios";
import config from "../config.json";
import 'bootstrap/dist/css/bootstrap.min.css';

function CroppedImage({ setResultData, setLoading, imageUrl, personID, personName, faceEmbedding, faceCount, cropX, cropY, cropWidth, cropHeight }) {
  const [croppedImage, setCroppedImage] = useState(null);
  const serviceUrl = config.serviceUrl;

  // Function to handle image cropping
  const handleImageCrop = () => {
    if (imageUrl) {
      const image = new Image();
      image.src = imageUrl;
      image.onload = () => {
        const canvas = document.createElement('canvas');
        const squareSize = Math.min(cropWidth, cropHeight);
        canvas.width = squareSize;  // Set both width and height to create a square canvas
        canvas.height = squareSize;
        const ctx = canvas.getContext('2d');
        
        // Calculate the center coordinates for cropping
        const centerX = (cropWidth - squareSize) / 2;
        const centerY = (cropHeight - squareSize) / 2;

        ctx.drawImage(
          image,
          cropX + centerX,
          cropY + centerY,
          squareSize,
          squareSize,
          0,
          0,
          squareSize,
          squareSize
        );

        // Get the cropped image as a data URL
        const croppedImageUrl = canvas.toDataURL('image/jpeg');
        setCroppedImage(croppedImageUrl);
      };
    }
  };

  async function handleClick() {
    let response;
    setLoading(true)
    // console.log("personID: ", personID);
    if (personID == null || personID == -1){
      // console.log("search-faces");
      const requestBody = {
        embedding: faceEmbedding,
      };
      response = await axios.post(serviceUrl + "/search-faces", requestBody, config);
    }
    else {
      const requestBody = {
        query: "person",
        person_list: [personID]
      };
      // console.log("search");
      response = await axios.post(serviceUrl + "/search", requestBody, config);
    }
    
    if (response.data) {
      setLoading(false)
      setResultData(response.data);
    };
  }

  // Update the cropped image when component mounts or crop coordinates change
  useEffect(() => {
    handleImageCrop();
  }, [imageUrl, cropX, cropY, cropWidth, cropHeight]);

  return (
    <div className="col-md-2 mb-3 mx-1" onClick={handleClick}>
      <div className="cropped-image-container d-flex justify-content-center align-items-center rounded border" style={{ width: '100px', height: '100px', cursor: 'pointer' }}>
        {croppedImage && (
          <img
            src={croppedImage}
            alt="Cropped"
            className="w-100 h-100 rounded"
            style={{ objectFit: 'cover' }}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={`Name: ${personName}\n${faceCount ? 'Face Count: ' : ''} ${faceCount  ? faceCount : ''}`}
          />
        )}
      </div>
    </div>
  );
  }

export default CroppedImage;
