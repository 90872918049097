import React, { useState, useRef, useEffect } from "react";
import { Modal } from 'react-bootstrap';
import { Tooltip } from 'bootstrap/dist/js/bootstrap.min.js';


const ContentModal = ({ show, setShowModal, children, orderBy }) => {
    const videoEl = useRef(null);
    const [videoLength, setVideoLength] = useState(0);
    const [videoCurrentTime, setVideoCurrentTime] = useState(0);
    const handleLoadedMetadata = () => {
        const video = videoEl.current;
        setVideoLength(video.duration);
    };
    const playVideo = (timeInSeconds) => {
        if (videoEl.current) {
            videoEl.current.currentTime = timeInSeconds;
            videoEl.current.play();
        }
    };
    const handleTimeUpdate = () => {
        setVideoCurrentTime(videoEl.current.currentTime);
    }
    let video_segments;
    if (children.segments) {
        video_segments = children.segments.sort((a, b) => a[0] - b[0]);
    }

    let video_transcripts;
    if (children.transcripts) {
        video_transcripts = children.transcripts.sort((a, b) => a[0] - b[0]);
    }


    let video_start_point = 0;
    if (children.type === "video") {
        if (orderBy === "query_str") {
            if (children.transcripts.length) {
                video_start_point = children.transcripts[0][0];
            }
            else if (children.segments.length) {
                video_start_point = children.segments[0][0];
            }
        }
        else {
            if (children.segments.length) {
                video_start_point = children.segments[0][0];
            }
            else if (children.transcripts.length) {
                video_start_point = children.transcripts[0][0];
            }
        }
    }

    useEffect(() => {
        Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]')).forEach(tooltipNode => new Tooltip(tooltipNode));
        document.querySelectorAll('.tooltip.show').forEach(tooltip => {
            tooltip.remove();
        });

    }, [show]);

    return (
        <Modal
            show={show}
            onHide={() => { setShowModal(false); }}
            size="xl"
            className="modal-dialog-scrollable"
        >
            <Modal.Header closeButton>
                <Modal.Title>{children.raw_path}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                {children.type === 'video' &&
                    <>
                        <video
                            width="100%"
                            height="auto"
                            ref={videoEl}
                            onLoadedMetadata={handleLoadedMetadata}
                            onTimeUpdate={handleTimeUpdate}
                            controls
                            autoPlay>
                            <source src={children.raw_path + (children.type === "video" ? "#t=" + video_start_point : "")} type="video/mp4" />
                        </video>

                        {/* Plot Video Segments */}
                        {video_segments.length ?
                            <div className="row align-items-center mt-3">
                                <div className="col-12 col-md-2">
                                    Visual Clues:
                                </div>
                                <div className="col-12 col-md-10">
                                    <div className="progress position-relative" role="progressbar" aria-label="Basic example" aria-valuemin="0" aria-valuemax="100">

                                        {video_segments.map((s, i) => {
                                            return <div
                                                key={i}
                                                className="progress-bar position-absolute"
                                                role="button"
                                                style={{
                                                    left: `${(100 * s[0] / videoLength)}%`,
                                                    width: `${Math.ceil(100 * (s[1] - s[0]) / videoLength)}%`,
                                                    height: '100%'
                                                }}
                                                onClick={() => playVideo(s[0])}
                                            >
                                            </div>
                                        })}
                                        <div
                                            className="progress-bar position-absolute bg-dark"
                                            style={{
                                                left: `${(100 * videoCurrentTime / videoLength)}%`,
                                                width: "2px",
                                                height: '100%',
                                                color: "black"
                                            }}
                                            role="progressbar"></div>
                                    </div>
                                </div>
                            </div>
                            : ""}

                        {/* Plot Video Transcripts */}
                        {video_transcripts.length ?
                            <div className="row align-items-center mt-3">
                                <div className="col-12 col-md-2">
                                    Audio Clues:
                                </div>
                                <div className="col-12 col-md-10">
                                    <div className="progress position-relative" role="progressbar" aria-label="Basic example" aria-valuemin="0" aria-valuemax="100">

                                        {video_transcripts.map((s, i) => {
                                            return <div
                                                key={i}
                                                className="progress-bar position-absolute"
                                                role="button"
                                                style={{
                                                    left: `${(100 * s[0] / videoLength)}%`,
                                                    width: `${Math.ceil(100 * (s[1] - s[0]) / videoLength)}%`,
                                                    height: '100%'
                                                }}
                                                onClick={() => playVideo(s[0])}
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                                title={s[2]}
                                            >
                                            </div>
                                        })}
                                        <div
                                            className="progress-bar position-absolute bg-dark"
                                            style={{
                                                left: `${(100 * videoCurrentTime / videoLength)}%`,
                                                width: "2px",
                                                height: '100%',
                                                color: "black"
                                            }}
                                            role="progressbar"></div>
                                    </div>
                                </div>
                            </div>
                            : ""}

                    </>
                }
                {children.type === 'image' &&
                    <img src={children.raw_path} className="mw-100" alt={children.raw_path} />
                }
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary" onClick={() => { setShowModal(false); }}>
                    Close
                </button>
                <a className="btn btn-primary" href={children.raw_path} target="_blank" rel="noreferrer">
                    Download
                </a>
            </Modal.Footer>
        </Modal>
    );
};

export default ContentModal;
